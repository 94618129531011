export default {
  s1: {
    h1: 'GBWhatsApp Download APK (Updated) %1 2024 – Official Latest (Anti-Ban)',
    h1_0704: 'GBWhatsApp Download APK (Updated) %1 2024 – Official Latest (Anti-Ban)',
    h1_0712: 'GBWhatsApp Apk Download %1 2024(Updated)',
    h1_0305: 'GB WhatsApp Download APK Updated Login FIXED Version',
    recommend1: 'Security verified',
    recommend2: 'Official certification',
    adimg_1: require('@/assets/gb/p-1.webp'),
    intro_1:
      'GBWhatsApp is the modified version of the official WhatsApp. You get many extra features with GBWhatsApp instead of using the official WhatsApp. Features like hiding online status, airplane mode are the core benefits of GBWhatsApp. It has extra privacy available in it, and there is no need to pay anything to use this mod.',
    title_1: 'GBWhatsApp vs WhatsApp – What\'s The Difference?',
    intro_2:
      'For those of you wondering what the difference is between GB WhatsApp and WhatsApp and whether you should try GB WhatsApp APK yourself, please read the following, These include features such as attractive themes, the ability to hide internet status, enabling airplane mode, and more. Below is a comparison of some of the main features of GBWhatsApp versus WhatsApp, and you will be attracted.',
    intro_2_new:
      'Instead of WhatsApp, there are numerous modifications in GBWhatsApp APK. Such as good-looking theme availability, hiding internet status, turning on aeroplane mode, and so on. The following is a comparison of some of the primary features of GBWhatsApp vs. WhatsApp.',
    intro_2_redirect:
      "If you're looking for an alternative to WhatsApp, you might want to check out GBWhatsApp APK. This modified version of the popular messaging app offers a range of extra features, including customizable themes, the ability to hide your online status, and even an airplane mode. To help you decide whether GBWhatsApp is right for you, here's a comparison of some of its key features versus the standard WhatsApp app.",
  },
  apkdetail: {
    title_1: 'GBWhatsApp Download APK',
  },
  s2: {
    title_2: 'Summary of GBWhatsApp APK features',
    intro_3:
      'GBWhatsApp offers customization features and so much more. Check the list down below, and we\'re sure that you\'ll be convinced of getting this mod for your device.',
    adimg_1: require('@/assets/gb/ad1.webp'),
    adimg_1_webp: require('@/assets/gb/ad1.webp'),
    adimg_1_new: require('@/assets/gb/ad1new.webp'),
    adimg_1_new_webp: require('@/assets/gb/ad1new.webp'),
    ul: [
      {
        head: 'Auto Reply',
        content:
          'Firstly, you can also use this auto-reply feature when you want to reply to any of your friends at any time. ',
        img: require('@/assets/gb/ulcontent-1.webp'),
        img_webp: require('@/assets/gb/ulcontent-1.webp'),
      },
      {
        head: 'DND Mod',
        content:
          'If you are using some other app on your Android phone and don\'t want to get disturbed by Whatsapp messages then you can use DND feature to disable the internet connection for GB Whatsapp only.',
      },
      {
        head: 'Text messages Broadcast',
        content: 'You can send Broadcast text messages to groups which is a great feature.',
      },
      {
        head: ' Filter messages',
        content:
          'The GB Whatsapp APK is formulated with the Filter Messages feature which provides the user with an option to clear chat which can also filter your messages. ',
      },
      { head: ' Anti-revoke message', content: 'Comes with Anti-revoke messages feature. ' },
      {
        head: ' Share live locations: ',
        content: 'Additionally, the user can also share their live locations with friends by using GB Whatsapp 2024. ',
        img: require('@/assets/gb/ulcontent-2.webp'),
        img_webp: require('@/assets/gb/ulcontent-2.webp'),
      },
      {
        head: ' Outstanding effects: ',
        content:
          'Users can add outstanding and unique effects while sending pictures and videos to their friends and loved ones. ',
      },
      { head: 'Revoke multiple messages:', content: 'At one time, you can revoke multiple messages. ' },
      {
        head: 'Emoji Effective',
        content:
          'Many popular custom emoji are also interactive when sent in 1-on-1 chats – any user can tap to play synchronized, full-screen effects. <br> Try it with common emotions like ',
        img: require('@/assets/gb/ulcontent-5.webp'),
        img_webp: require('@/assets/gb/ulcontent-5.webp'),
      },
      {
        head: 'People Nearby',
        content:
          "We added People Nearby for exchanging contact info face-to-face. With this update, People Nearby can help you meet new friends, or arrange a last-minute date for Valentine's Day.",
        img: require('@/assets/gb/ulcontent-6.webp'),
        img_webp: require('@/assets/gb/ulcontent-6.webp'),
      },
      {
        head: 'Send Maximum Pictures:',
        content:
          'Additionally, as compared to official Whatsapp you can send more than 90 pictures at a time. Also, you can send 50 MB video clips and 100 MB audio clips to your contact.',
      },
      {
        head: 'Endless Themes: ',
        content:
          'Plus, in this modified version of Whatsapp theme feature is also introduced. So there are so many amazing themes and Emojis that you can apply to your phone according to your mode. ',
        img: require('@/assets/gb/ulcontent-3.webp'),
        img_webp: require('@/assets/gb/ulcontent-3.webp'),
      },
      {
        head: 'Download Statuses:',
        content:
          'Another great feature of this app is that you can download the pictures and videos of statuses uploaded by other contacts. ',
      },
      {
        head: 'Amazing Font: ',
        content:
          'Are you bored from the old font? Then, with this feature, you can choose the font of your choice. Customize your favorite font with this feature. ',
      },
      {
        head: 'Messages History: ',
        content: 'You can check the history of revoked messages from your contacts and groups. ',
      },
      { head: 'Alter contacts:', content: 'Alter the media visibility of a particular contact in your gallery. ' },
      { head: 'Mark the unread messages:', content: 'From the notification, you can mark the read messages. ' },
      {
        head: 'Select all chat: ',
        content: 'With this app, one can select all the chats at one time from the home screen. ',
        img: require('@/assets/gb/ulcontent-4.webp'),
        img_webp: require('@/assets/gb/ulcontent-4.webp'),
      },
      { head: 'Hide your status:', content: 'The voice recording status can be hidden. ' },
      { head: 'Best Image Quality: ', content: 'With GB Whatsapp you can send pictures of high resolution. ' },
      {
        head: 'Language:',
        content:
          'Another best feature, with the help of this language feature, you can select the language from the default lost. ',
      },
      {
        head: 'Notification: ',
        content:
          'This app also allows you to get a notification when anyone from your contact lists changes their profile picture.',
      },
      {
        head: 'Pop up Notifications:',
        content:
          'Another incredible feature of GB Whatsapp APK is that you can hide your pop up notifications of this app from your main screen. ',
      },
    ],

    title_3: 'Installation guide',
    intro_4:
      'You can not find the GBWhatsApp MOD version on Google Play, so you need to download it from the site and then install it manually. If you do not know how to install GB WhatsApp on your Android you may follow this installation guide.',
    intro_5:
      'To install an APK file into the operating system of your Android, you may need to turn on the \'Unknown sources\', located in settings>security>unknown sources. Then just follow these steps with images as shown below.',
    adimg_2: require('@/assets/gb/ad4gb.webp'),
    adimg_2_webp: require('@/assets/gb/ad4gb.webp'),
    title_4: 'Download All Latest Mod for Android Directly (Officially)',
  },
  s2new: {
    intro_3:
      'GBWhatsApp APK provides customization features and a plethora of other options. Refer to the list below, and we are confident that you will be persuaded to acquire this modification for your device.',
    ul: [
      {
        head: 'Auto Reply',
        content: 'To begin, you can use this auto-reply option whenever you wish to respond to any of your friends.',
      },
      {
        head: 'DND Mod',
        content:
          'If you are utilizing an alternate application on your Android device and wish to avoid interruptions from WhatsApp messages, you may utilize the DND feature to suspend internet access solely for GB WhatsApp.',
      },
      {
        head: 'Text messages Broadcast',
        content: 'Broadcast text messages can be sent to groups, which is a fantastic function.',
        img: require('@/assets/gb/ulcontent-7.webp'),
        img_webp: require('@/assets/gb/ulcontent-7.webp'),
      },
      {
        head: ' Filter messages',
        content:
          'The Filter Messages function of the GB WhatsApp APK gives the user the ability to clear chat, which can also filter your messages.',
      },
      { head: ' Anti-revoke message', content: 'Comes with Anti-revoke messages feature.' },
      {
        head: ' Share live locations: ',
        content:
          'Furthermore, the user can use GB Whatsapp APK 2024 to communicate their current position with friends, so your friends can find you when you want them to.',
        img: require('@/assets/gb/ulcontent-2.webp'),
        img_webp: require('@/assets/gb/ulcontent-2.webp'),
      },
      {
        head: ' Outstanding effects: ',
        content:
          'Users can enhance their pictures and videos with exceptional and distinctive effects when sending them to their friends and loved ones.',
      },
      { head: 'Revoke multiple messages:', content: 'You can revoke multiples messages when you want to do it' },
      {
        head: 'Emoji Effective',
        content:
          'Many of the beloved custom emojis can come to life in 1-on-1 chats as they sync with full-screen effects and respond to user taps. You can try it out by expressing common emotions like...',
        img: require('@/assets/gb/ulcontent-5-new.webp'),
        img_webp: require('@/assets/gb/ulcontent-5-new.webp'),
      },
      {
        head: 'People Nearby',
        content:
          "We have introduced the People Nearby feature, which enables users to exchange contact information in person. With this update, People Nearby can facilitate the process of meeting new acquaintances or organizing an impromptu Valentine's Day date.",
        img: require('@/assets/gb/ulcontent-6-new.webp'),
        img_webp: require('@/assets/gb/ulcontent-6-new.webp'),
      },
      {
        head: 'Send Maximum Pictures:',
        content:
          'In addition to its official counterpart, GB WhatsApp allows you to send over 90 pictures simultaneously. Furthermore, you can send video clips up to 50 MB and audio clips up to 100 MB to your contacts.',
      },
      {
        head: 'Endless Themes: ',
        content:
          'Moreover, the modified version of WhatsApp also includes theme customizations. You can choose from a plethora of intriguing themes and emojis to apply to your phone according to your mood.',
        img: require('@/assets/gb/ulcontent-3-new.webp'),
        img_webp: require('@/assets/gb/ulcontent-3-new.webp'),
      },
      {
        head: 'Download Statuses:',
        content:
          'Another remarkable feature of GB WhatsApp APK is that you can download the pictures and videos that your contacts upload as status updates. ',
      },
      {
        head: 'Amazing Font: ',
        content:
          'Tired of the same old font? This feature lets you switch to a font of your preference. Personalize your preferred font with ease using this feature.',
      },
      {
        head: 'Messages History: ',
        content: 'You can review the history of messages that have been revoked by your contacts and groups.',
      },
      {
        head: 'Alter contacts:',
        content: 'Adjust the media visibility settings for a specific contact in your gallery. ',
      },
      { head: 'Mark the unread messages:', content: 'You can mark messages as read directly from the notification.' },
      {
        head: 'Select all chat: ',
        content: 'GBWhatsApp APK allows users to select multiple chats simultaneously directly from the home screen.',
      },
      { head: 'Hide your status:', content: 'It is possible to conceal the status of voice recordings. ' },
      { head: 'Best Image Quality: ', content: 'GB WhatsApp allows users to send high-resolution pictures.' },
      {
        head: 'Language:',
        content:
          'Another exceptional feature is the language selection function that allows users to choose from a list of default languages.',
        img: require('@/assets/gb/ulcontent-4-new.webp'),
        img_webp: require('@/assets/gb/ulcontent-4-new.webp'),
      },
      {
        head: 'Notification: ',
        content:
          'This application also enables users to receive notifications when someone from their contact list changes their profile picture. ',
      },
      {
        head: 'Pop up Notifications:',
        content:
          'Another remarkable feature of GB WhatsApp APK is the ability to conceal pop-up notifications from the app on your main screen.',
      },
    ],

    title_3: 'Installation guide',
    intro_4:
      'You can not find the GBWhatsApp MOD version on Google Play, so you need to download it from the site and then install it manually. If you do not know how to install GB WhatsApp on your Android you may follow this installation guide.',
    intro_5:
      'To install an APK file into the operating system of your Android, you may need to turn on the \'Unknown sources\', located in settings>security>unknown sources. Then just follow these steps with images as shown below.',
    intro_5_new:
      "To install an APK file on your Android device, you'll have to enable 'Unknown sources' under settings>security>unknown sources. Once done, follow the step-by-step instructions with the visual aids provided below.",
    adimg_2: require('@/assets/gb/ad4gb.webp'),
    adimg_2_webp: require('@/assets/gb/ad4gb.webp'),
    title_4: 'Download All Latest Mod for Android Directly (Officially)',
  },
  s2_0704: {
    ul: [
      {
        head: 'Auto Reply',
        content: 'Auto Reply allows you to reply to other messages at any time when you are not convenient.',
        img: require('@/assets/gb/ulcontent-8.webp'),
        img_webp: require('@/assets/gb/ulcontent-8.webp'),
      },
      {
        head: 'DND Mod',
        content:
          "If you don't want to be disturbed by WhatsApp messages when you are on other applications, you can use DND feature to disable the internet connection for GB Whatsapp only.",
      },
      {
        head: 'Text messages Broadcast',
        content:
          'You can send a single text message to multiple recipients simultaneously, both individuals and groups.',
      },
      {
        head: ' Filter messages',
        content:
          'Filter Messages features in GB WhatsApp, which allows users to clear their chat history while also filtering their messages.',
      },
      {
        head: ' Anti-revoke message',
        content:
          'The Anti-Revoke Message feature in GB WhatsApp prevents revoked or deleted messages from being hidden or removed in the chat.  It allows users to view and read messages that have been deleted by the sender.  This feature can be useful in situations where you want to retain access to important information or conversations even if the sender decides to delete them.',
      },
      {
        head: ' Share live locations: ',
        content: 'Share your live locations with friends when you want the others find you.',
        img: require('@/assets/gb/ulcontent-9.webp'),
        img_webp: require('@/assets/gb/ulcontent-9.webp'),
      },
      {
        head: ' Outstanding effects: ',
        content:
          "If you don't want your chats to be ordinary, the GB WhatsApp APK offers a variety of outstanding and unique effects for you to choose from.",
      },
      {
        head: 'Revoke multiple messages:',
        content:
          'This feature allows users to delete multiple messages at once. Normally, in the standard version of WhatsApp, you can only delete messages individually or in a conversation. However, with GB WhatsApp APK, you can select and delete multiple messages simultaneously via use this feature, saving time and effort. ',
      },
      {
        head: 'Emoji Effective',
        content:
          'Wonderful feature. Many popular custom emoji are also interactive when sent in 1-on-1 chats – any user can tap to play synchronized, full-screen effects.<br>Try it with common emotions like',
        img: require('@/assets/gb/ulcontent-10.webp'),
        img_webp: require('@/assets/gb/ulcontent-10.webp'),
      },
      {
        head: 'People Nearby',
        content:
          'People Nearby allows users to discover and connect with people who are physically close to their location. By using the "People Nearby" feature, you can find and connect with people in your vicinity who are interested in meeting new people, making friends, or engaging in social activities.',
        img: require('@/assets/gb/ulcontent-11.webp'),
        img_webp: require('@/assets/gb/ulcontent-11.webp'),
      },
      {
        head: 'Send Maximum Pictures:',
        content:
          'As compared to official Whatsapp you can send more than 90 pictures at a time. Also, you can send 50 MB video clip and 100 MB audio clip to your contact.',
      },
      {
        head: 'Endless Themes: ',
        content:
          'GBWhatsApp offers so many amazing themes and Emojis that you can apply to your phone according to your mode.',
        img: require('@/assets/gb/ulcontent-12.webp'),
        img_webp: require('@/assets/gb/ulcontent-12.webp'),
      },
      {
        head: 'Download Statuses:',
        content:
          'GBWhatsApp APK allows you to download the pictures and videos of statuses uploaded by other contacts.',
      },
      {
        head: 'Amazing Font: ',
        content:
          'GBWhatsApp APK not only has a variety of interesting fonts for you to choose from, but also allows users to customize their favorite fonts.',
      },
      {
        head: 'Messages History: ',
        content: 'You can check the history of revoked messages from your contacts and groups.',
      },
      {
        head: 'Alter contacts:',
        content:
          '"Alter contacts" refers to the ability to modify or edit the information of your contacts. It provides the flexibility to update or correct contact information as needed, ensuring accurate and up-to-date records for effective communication.',
      },
      { head: 'Mark the unread messages:', content: 'From the notification, you can mark the read messages.' },
      {
        head: 'Select all chat: ',
        content:
          'When you want to send messages to multiple people at once, you must have been frustrated by the limitation of WhatsApp, which only allows selecting five conversations at a time. But with GB WhatsApp, you can overcome this limitation as it allows you to select all conversations at once from the home screen.',
        img: require('@/assets/gb/ulcontent-13.webp'),
        img_webp: require('@/assets/gb/ulcontent-13.webp'),
      },
      {
        head: 'Hide your status:',
        content: "When you are online and you don't want to be known, then you can hide your status.",
      },
      { head: 'Best Image Quality: ', content: 'With GB Whatsapp you can send pictures of high resolution.' },
      {
        head: 'Language:',
        content:
          'Another best feature, with the help of this language feature, you can select the different language from the default lost.',
      },
      {
        head: 'Notification: ',
        content: 'You can get the notification when anyone from your contact lists changes their profile picture.',
      },
      {
        head: 'Pop up Notifications:',
        content:
          'Pop-up notifications appear on the screen to alert users about new messages, updates, or events. and provide users with quick and convenient access to important information without interrupting their current tasks. However, GBWhatsApp APK allows you hide your pop up notifications from your main screen too.',
      },
    ],
    title_3: 'Installation guide',
    adimg_2: require('@/assets/gb/ad4gb.webp'),
    adimg_2_webp: require('@/assets/gb/ad4gb.webp'),
  },
  s2_0712: {
    adimg_3: require('@/assets/gb/way.webp'),
    adimg_3_webp: require('@/assets/gb/way.webp'),
  },
  s3: {
    title_6: 'More Android Mods',
    title_7: 'Android Games',
    download_btn: 'DOWNLOAD',
    big_btn: 'Load More Android Updates',
  },
  s4: {
    title_5: 'The 4 best tools make WhatsApp more useful & poewrful.',
  },
}
