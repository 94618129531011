<template>
	<div id="head" class="head" @mouseleave="leaveNav()">
		<!-- <div class="head-logo-row" @click="$router.push('/')" style=""> -->
		<div class="head-logo-row" @click="goto('/')" style="">
			<!-- <img :src="appLogo" class="head-img" alt="logo"> -->
			<img src="@/assets/gb/GB_new_icon_small@2x.webp" class="head-img" alt="logo">
			<div class="head-name">
				{{appName}}
			</div>
		</div>
		<!-- <div class="nav-row" id="nav-row">
			<div class="nav-item" :class="{'active':nav == 1}" @mouseover="clickNav(1)">
				Products
			</div>
			<div class="nav-item" :class="{'active':nav == 2}" @mouseover="clickNav(2)">
				Android Apps
			</div>
			<div class="nav-item item-3" :class="{'active':nav == 3}" @mouseover="clickNav(3)">
				Blogs
			</div>
			<div class="nav-item" :class="{'active':nav == 4}" @mouseover="clickNav(4)">
				About
			</div>
		</div> -->

		<!-- <transition name="dropdown-fade-show">
			<div v-show="dropDownShow" :class="`drop-${nav}`" @touchmove.prevent class="dropdown drop-item-arrow">
				<div class="drop-item-row" :class="`drop-item-${nav}`">
					<div class="drop-down-item" v-for="(data,i) in dropDownList[`nav_${nav}`]" :key="i" @click="goto(data.url)">
						{{data.name}}
					</div>
				</div>
			</div>
		</transition> -->

		<!-- 无dropdown -->
		<div class="nav-row" id="nav-row">
			<div class="nav-item" v-for="(data,i) in headerList" :key="i" @click="goto(data.url)">
				{{data.name}}
			</div>
			<div class="nav-item" @click="showModal()">
				Download
			</div>
		</div>
	</div>
</template>


<script>

export default {
	name: "Tophead",
	data() {
		return {
			dropDownShow: false,
			nav: null,
			from: null,
			dropDownList: {
				nav_1: [
					{ name: "GBWhatsApp", url: "/" },
					{ name: "YoWhatsApp", url: "/yowhatsapp.html" },
					{ name: "OBWhatsApp", url: "/obwhatsapp.html" },
					{ name: "WhatsApp Plus", url: "/whatsapp2plus.html" },
				],
				nav_2: [
					{ name: "App", url: "section-3" },
					{ name: "Games", url: "section-3" },
				],
				nav_3: [
					{ name: "Blogs", url: "/blogs" },
				],
				nav_4: [
					{ name: "DMCA", url: "/dmca" },
				],
			},
			headerList:[
				{ name: "YoWhatsApp", url: "/yowhatsapp.html" },
				{ name: "OBWhatsApp", url: "/obwhatsapp.html" },
				{ name: "WhatsApp Plus", url: "/whatsapp2plus.html" },
				{ name: "Blogs", url: "/blogs" },
				// { name: "DMCA", url: "/dmca" },
			],
			// download start
			from: null,
			filename: null,
			isOffical: 0,
			name: null,
			domainMap: {
				home: {
				domain: "gbwhatsapp.download",
				appName: "GB_gbwhatsapp.download",
				},
				gb: {
				domain: "gbwhatsapp.download",
				appName: "GB_gbwhatsapp.download",
				},
				ob: {
				domain: "gbwhatsapp.download",
				appName: "OB_gbwhatsapp.download",
				},
				yo: {
				domain: "gbwhatsapp.download",
				appName: "YO_gbwhatsapp.download",
				},
				plus: {
				domain: "gbwhatsapp.download",
				appName: "PLUS_gbwhatsapp.download",
				},
			},
			baseURL: "https://gbproapks.com",
			// download end

		};
	},
	props: {
		appName: String,
		appLogo: String,
	},

	// mounted() {
	// 	document.addEventListener("click", (event) => {
	// 		var cDom = document.querySelector("#nav-row");
	// 		var tDom = event.target;
	// 		if (cDom == tDom || cDom.contains(tDom)) {
	// 			// console.log("in");
	// 		} else {
	// 			this.dropDownShow = false;
	// 			this.nav = null;
	// 		}
	// 	});
	// },
	// download start
	beforeMount() {
		[this.from, this.filename, this.isOffical] = [
		this.$global.GetQueryString("from") || "",
		this.$global.GetQueryString("filename") || "",
		parseInt(this.$global.GetQueryString("isOffical")) || 0,
		];
		console.log(`from:${this.from},filename:${this.filename}`);
  	},
	// download end

	methods: {
		goto(link) {
			if (this.nav != 2) {
				window.location.href = link;
			}
			
			if (this.nav ==2) {
				if (this.$route.name == "download_pc") {
					this.$router.push({
						name: this.$store.state.lastPage,
						params: { scrollTo: link },
					});
					return;
				}
				this.scroll(link);
			}
		},
		toblog(){
			this.nav = 3
			window.location.href = '/blogs';
		},
		scroll(to) {
			console.log(to);
			document.querySelector(`#${to}`).scrollIntoView({
				behavior: "smooth",
			});
		},
		clickNav(nav) {
			console.log(this.$route);

			this.nav = nav;
			if (this.dropDownShow) {
				return;
			}

			this.dropDownShow = !this.dropDownShow;
			if (!this.dropDownShow) {
				this.nav = null;
			}
		},
		leaveNav() {
			this.dropDownShow = false;
			this.nav = null;
		},
		// download start
		concatenatedString () {
			let add = "";
			switch (this.name) {
				case "ob":
				add = "/obwhatsapp-apk-download";
				break;
				case "plus":
				add = "/whatsapp-plus-apk-download";
				break;
				case "yo":
				add = "/yowhatsapp-apk-download";
				break;
				default:
				add = "";
				break;
			}

			return add;
		},
		download () {
			if (this.name) {
				console.log(this.domainMap);
				window.location.href = `/download?apps=${this.name}&from=${this.from
				}&filename=${this.filename}&isOffical=${this.isOffical
				}&domain=${this.domainMap[this.name].domain}&appName=${this.domainMap[this.name].appName
				}`;
			} else {
				window.location.href = `/download?apps=gb&from=${this.from}&filename=${this.filename}&isOffical=${this.isOffical}&domain=gbwhatsapp.download&appName=GB_gbwhatsapp.download`;
			}

			this.$logEvent(`click_${this.name}home_download`);
			},
			showModal () {
			if (!this.$root.isMobileDevice) {
				this.download();
			}
			if (this.$root.shouldRedirect) {
				this.download();
			} else {
				this.$root.isModalVisible = true;
				this.$logEvent(`show_modal_ad`);
			}
		},
		//download end
	},
};
</script>
