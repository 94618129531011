export default {
	s1: {
		h1: 'Discover OBWhatsApp APK New Features 2025',
		recommend1: 'أمان',
		recommend2: 'الرسمية',
		adimg_1: require('@/assets/gb/p-1.webp'),
		intro_1: 'يعتبر واتساب عمر العنابي OBWhatsApp أفضل تطبيق يوفر لمستخدميه مميزات رائعة و متنوعة ومتطورة عن نسخة الواتساب الأخضر التقليدية وكذلك هو أفضل من كل الإصدارات الأخرى الشبيهة به، وذلك للمستوى المرتفع الذى يقدمه واتساب عمر العنابي لمستخدميه من خصائص فريدة حل لأغلب المشاكل التي كانت و سنوضح لك فى مقالنا اليوم كافة تلك المزايا الحصرية لواتس اب عمر العنابي بالإضافة لشرح كيفية تحميل واتساب عمر العنابي سواء للهاتف الجوال الاندرويد أو لتحميله على الكمبيوتر، كل هذا وأكثر سنوضح لك في مقالنا اليوم.',
		title_1: 'OBWhatsApp vs WhatsApp - ما الفرق؟',
		intro_2: 'هناك العديد من التغييرات في OBWhatsApp بدلاً من WhatsApp. مثل توفر السمات ذات المظهر الجميل ، وإخفاء حالة الاتصال بالإنترنت ، وتشغيل وضع الطائرة ، وما إلى ذلك ، فيما يلي قائمة ببعض الميزات الرئيسية لبرنامج OBWhatsApp VS WhatsApp.',
	},
	apkdetail: {
		title_1: 'OBWhatsApp apk',
	},
	s2: {
		title_2: 'What\'s New in OB WhatsApp\'s Latest Update?',
		intro_3: 'يوفر OBWhatsApp ميزات التخصيص وأكثر من ذلك بكثير. تحقق من القائمة أدناه ، ونحن على يقين من أنك ستقتنع بالحصول على هذا التعديل لجهازك.',
		adimg_1: require('@/assets/ob/ad1.webp'),
		ul: [
			{ head: 'Do Not Disturb (DND) Mode', content: 'Block OB WhatsApp\'s access to the internet while keeping other apps online, allowing you to work or relax without being distracted by incoming messages.' },
			{ head: 'Expanded Customization', content: 'With access to a wider selection of themes and customization options, you can create a messaging interface that reflects your style.' },
			{ head: 'Improved Privacy Settings', content: 'Now you can hide your online status, typing notifications, and read receipts (blue ticks), giving you more control over your privacy.' },
			{ head: 'Jump to First Chat', content: 'Instantly scroll to the beginning of any conversation, making it faster to revisit your earliest messages.', img: require('@/assets/ob/ulcontent-1.png'), alt: 'First Message' },
			{ head: 'Automated Replies', content: 'Configure auto-replies to send instant responses when you\'re away, helping you maintain communication even when you\'re occupied.' },
			{ head: 'Message Sorting', content: 'Keep your chats organized by filtering messages, which simplifies managing ongoing conversations.' },
			{ head: 'Media Sharing Upgrades', content: 'Send larger files, including photos, videos, and documents, with a maximum size of up to 100MB—perfect for sharing high-quality media.' },
			{ head: 'Message Scheduler', content: 'This handy feature allows you to schedule messages to be sent later, making it easier to stay on top of your communication.', img: require('@/assets/ob/ulcontent-2.png'), alt: 'Message Scheduler' },
			{ head: 'Group Chat Enhancements', content: 'Manage group chats more efficiently with upgraded group settings, offering better tools for communication and customization.' },
			{ head: 'Islamic Content Collection', content: 'Access to Islamic content, including the Quran and Hadith, is now seamlessly built into the app for easy access to important resources.' },
			{ head: 'Updated User Interface', content: 'Enjoy a more streamlined and intuitive design, making navigation smoother and the overall experience more enjoyable.', img: require('@/assets/ob/ulcontent-3.png'), alt: 'User Interface' },
			{ head: 'Endless Theme Options', content: 'Personalize the look and feel of your app with a vast collection of themes and emoji packs, tailored to match your preferences.' },
			{ head: 'Optimized Storage Management', content: 'New tools allow users to efficiently manage files and chat storage, making it easier to keep your device clutter-free.' },
		],

		title_3: 'كيفية تثبيت تطبيق واتس اب عمر العنابي',
		intro_4: ' التي قمنا بعرضها وتوضيحها سالفا. <br>نقوم بعمل نسخة احتياطية من المحادثات تحسبا لفقدان النسخة الأصلية خلال عملية التحميل والتثبيت; ويتم ذلك عن طريق الذهاب إلى الإعدادات ثم نقوم بالدخول علي اختيار الدردشات. <br>و كذألك نقوم بعمل نسخة احتياطية وتعتبر هذه الخطوة هي الخطوة الثانية من خطوات تثبيت واتساب obwhatsapp.<br>	وتعتبر الخطوة الثالثة من خطوات تثبيت تنزيل واتس اب عمر العنابي هي مسح أو إلغاء تثبيت تطبيق واتساب من الهاتف الجوال وتتم هذه الخطوة من خلال الذهاب إلى الإعدادات الخاصة بالجهاز الجوال.<br>ثم نذهب إلى خيار التطبيقات ونقوم بالضغط على خيار الواتساب ثم نقوم بعمل إلغاء تثبيت للتطبيق.',
		intro_5: 'ومن ثم نقوم بالخطوة الرابعة وهي خطوة تثبيت تطبيق تنزيل واتساب بلس; على الجهاز الجوال وذلك بعد الضغط علي الأمان ثم اختيار مصادر غير معروفة. <br> أما عن الخطوة الخامسة فهي عبارة عن الدخول إلي التطبيق من سطح المكتب الخاص بالجهاز الجوال ثم نقوم بالضغط على الخيار موافق ونسمح بالأذونات التي يطلبها التطبيق بدون قلق.',
		adimg_2: require('@/assets/gb/ad4ob.webp'),
		title_4: 'Download All Latest Mod for Android Directly (Officially)',
	},
	s3: {
		title_6: 'More Android Mods',
		title_7: 'Android Games',
		download_btn: 'DOWNLOAD',
		big_btn: 'Load More Android Updates',
	},
	s4: {
		title_5: 'The 4 best tools make WhatsApp more useful & poewrful.',
	},
}
