export default {
	s1: {
		h1: 'Yo WhatsApp Download APK New Version 2025 for Free',
		recommend1: 'Security verified',
		recommend2: 'Official certification',
		adimg_1: require('@/assets/gb/p-1.webp'),
		intro_1: 'YoWhatsApp is the modified version of the official WhatsApp. You get many extra features with YoWhatsApp instead of using the official WhatsApp. Features like hiding online status, airplane mode are the core benefits of YoWhatsApp. It has extra privacy available in it, and there is no need to pay anything to use this mod.',
		title_1: 'YoWhatsApp vs WhatsApp – What\'s The Difference?',
		intro_2: 'There are many changes in YoWhatsApp instead of WhatsApp. Like good-looking themes availability, hide online status, turn on airplane mode, etc. Below is a list of some of the main key features of YoWhatsApp VS WhatsApp.',
	},
	apkdetail: {
		title_1: 'Download YoWhatsApp APK',
	},
	s2: {
		title_2: 'Main Features of YoWhatsApp APK',
		intro_3: 'YoWhatsApp offers customization features and so much more. Check the list down below, and we\'re sure that you\'ll be convinced of getting this mod for your device.',
		adimg_1: require('@/assets/yo/ad1.webp'),
		ul: [
			{ head: 'DND Mode', content: 'Disable the internet connection for YoWhatsApp alone, perfect for when you don\'t want to be interrupted by messages.' },
			{ head: 'Auto Reply', content: 'Set automatic responses to your messages anytime, even when you\'re busy.'},
			{ head: 'Go to First Message', content: 'Click this option to go to the first message in a chat, saving you time.', img: require('@/assets/gb/ulcontent-1.webp'), alt: 'Go to First Message' },
			{ head: 'Message Filtering', content: 'Clear chats while filtering specific messages, keeping things organized.' },
			{ head: 'Special Effects', content: 'Add unique effects to pictures and videos before sending them in YoWhatsApp.' },
			{ head: 'Custom Fonts', content: 'YoWhatsApp supports users to choose from a wide range of fonts to personalize chats.' },
			{ head: 'Yo Themes', content: 'Enjoy a variety of themes and emojis, letting you customize your app\'s appearance to match your style.', img: require('@/assets/gb/ulcontent-2.webp'), alt: 'Yo Themes' },
			{ head: 'Anti-Delete Messages', content: 'Access the Anti-revoke feature, preventing others from deleting messages they\'ve sent to you.' },
			{ head: 'File Sharing', content: 'Share videos and audio files up to 1GB in size, convert videos into GIFs, and send up to 100 images simultaneously.' },
			{ head: 'Broadcast Texts', content: 'In YoWhatsApp\'s new version, you can send broadcast text messages to groups with ease.' },
			{ head: 'Contact Customization', content: 'Control the media visibility of specific contacts in your gallery.' },
			{ head: 'Unread Messages', content: 'Mark messages as unread directly from your notifications, useful for later checks.' },
			{ head: 'Save Status', content: 'Download photos and videos from other users\' statuses directly to your device.', img: require('@/assets/gb/ulcontent-3.webp'), alt: 'Save Status Videos' },
			{ head: 'High-Quality Images', content: 'Send high-resolution images without compression.' },
			{ head: 'Language Translation', content: 'Select and translate to your preferred language directly in the app.' },
			{ head: 'Select All Chats', content: 'Select all chats from the home screen at once for easier management.' },
			{ head: 'Pin Messages', content: 'Pin important messages in private chats for quick reference.' },
		],
		new: [
			"Improved anti-ban protection",
			"Support for Linked Devices feature",
			"Corrected black ticks color",
			"Resolved Playstore update message issue",
			"Fixed gap in updates page",
			"Addressed random crashes during Instagram-like status usage",
			"Corrected group name color issue",
			"Fixed avatar creation crash",
			"Restored 5-minute status option",
		],

		title_3: 'Installation guide',
		intro_4: 'You can not find the YoWhatsApp MOD version on Google Play, so you need to download it from the site and then install it manually. If you do not know how to install Yo WhatsApp on your Android you may follow this installation guide.',
		intro_5: 'To install an APK file into the operating system of your Android, you may need to turn on the \'Unknown sources\', located in settings>security>unknown sources. Then just follow these steps with images as shown below.',
		adimg_2: require('@/assets/yo/ad4yo.png'),
		title_4: 'Download All Latest Mod for Android Directly (Officially)',
	},
	s3: {
		title_6: 'More Android Mods',
		title_7: 'Android Games',
		download_btn: 'DOWNLOAD',
		big_btn: 'Load More Android Updates',
	},
	s4: {
		title_5: 'The 4 best tools make WhatsApp more useful & poewrful.',
	},
}
